import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

import RecordIcon from '../../../assets/movie-record.svg';
import { colors } from '../../const/Styles';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../const/Routes';

const Container = styled.article`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Header = styled.h3`
    display: block;
    background-color: ${colors.gray};
    font-weight: bold;
    font-size: 1.3rem;
    color: ${colors.footerIconSelected};
    padding: 10px;
    margin: 0;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${colors.black};
    min-height: 30vh;
    box-sizing: border-box;
    padding: 10px;
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
`;

const RecordButton = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    border: solid 2px ${colors.lightGray};
    box-sizing: border-box;
    justify-content: center;
    .react-svg {
        span {
            display: flex;
        }
        svg {
            width: 48px;
            height: 28px;
        }
    }
`;

type Props = {
    title: string;
};
const LessonCard: React.FC<Props> = ({ title, children }) => {
    const history = useHistory();
    const handleClickRecordButton = () => {
        history.push(Routes.LESSON_RECORD_SWING);
    };
    return (
        <Container>
            <Header>{title}</Header>
            <Content>
                <VideoContainer>{children}</VideoContainer>
                <RecordButton onClick={handleClickRecordButton}>
                    <ReactSVG
                        src={RecordIcon ?? ''}
                        wrapper='span'
                        className='react-svg'
                    />
                </RecordButton>
            </Content>
        </Container>
    );
};

export default LessonCard;
