import React, { useState, useCallback, ForwardedRef, useEffect } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Card,
    Box,
    Select,
    TextField,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';

import DominantButton from '../form/DominantButton';
import RecordingStore from '../../store/RecordingStore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            position: 'absolute',
            width: '80%',
            height: '70%',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            overflow: 'scroll',
        },
        videoBox: {
            display: 'flex',
            justifyContent: 'center',
            margin: 8,
            maxHeight: 500,
        },
        formBox: {
            margin: 20,
        },
        buttonBox: {
            display: 'flex',
            justifyContent: 'space-around',
            margin: 60,
        },
        form: {
            display: 'block',
            width: 300,
            '& > div.MuiFilledInput-root': {
                height: 40,
                width: 300,
                display: 'block',
            },
        },
        selectFormDiv: {
            display: 'flex',
            justifyContent: 'center',
        },
        textFieldFormDiv: {
            display: 'flex',
            justifyContent: 'center',
            marginLeft: 50,
            '& > div > div > input.MuiFilledInput-input': {
                paddingTop: 10,
            },
        },
        unitText: {
            margin: 'auto 0 3px 3px',
        },
        blueButton: {
            backgroundColor: theme.palette.primary.light,
        },
    }),
);

interface LocationState {
    customerId: string;
}

interface Props {
    video: Blob | null;
    toolImage: File | null;
    handleClose: () => void;
}

export default observer(
    function RegisterRecordingModal(props: Props, ref: ForwardedRef<unknown>) {
        const { video, toolImage, handleClose } = props;
        const classes = useStyles();
        const location = useLocation<LocationState>();

        const items = [
            'ドライバー',
            'フェアウェイウッド',
            'ユーティリティ',
            'アイアン',
            'ウェッジ',
            'パター',
        ];

        const [club, setClub] = useState<string>('');
        const [yard, setYard] = useState<string>('');
        const [videoSrc, setVideoSrc] = useState('');

        useEffect(() => {
            setVideoSrc(URL.createObjectURL(video));
        }, [video]);

        const handleChange = useCallback(
            (event: React.ChangeEvent<{ value: unknown }>) => {
                setClub(event.target.value as string);
            },
            [],
        );

        const onClickUpload = useCallback(
            (number: string) => {
                if (!video) return;
                RecordingStore.registerVideo(location.state.customerId, {
                    id: 1,
                    number,
                    video,
                    club,
                    yard,
                    toolImage,
                });
                handleClose();
            },
            [
                club,
                handleClose,
                location.state.customerId,
                toolImage,
                video,
                yard,
            ],
        );

        return (
            <Card ref={ref} tabIndex={-1} className={classes.card}>
                <Box className={classes.videoBox}>
                    <video
                        src={videoSrc}
                        autoPlay={true}
                        onPlay={(e) => {
                            // このイベントハンドラはiPadでのサムネイル非生成対策。動画のレンダリング直後に再生を停止しサムネイルのように見せる。
                            e.currentTarget.pause();
                        }}
                    />
                </Box>
                <Box className={classes.formBox}>
                    <div className={classes.selectFormDiv}>
                        <Select
                            className={classes.form}
                            // classes={{ root: classes.selectSize }}
                            variant='filled'
                            value={club}
                            onChange={handleChange}
                            defaultValue={items[0]}
                        >
                            {items.map((item) => {
                                return (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                    <br />
                    <div className={classes.textFieldFormDiv}>
                        <TextField
                            type='number'
                            className={classes.form}
                            // classes={{ root: classes.textFieldSize }}
                            variant='filled'
                            value={yard}
                            onChange={(e) => setYard(e.target.value)}
                        />
                        <Typography className={classes.unitText}>
                            ヤード
                        </Typography>
                    </div>
                </Box>
                <Box className={classes.buttonBox}>
                    <DominantButton
                        onClick={() => onClickUpload('1')}
                        className={classes.blueButton}
                    >
                        スイング1へ保存
                    </DominantButton>
                    <DominantButton
                        onClick={() => onClickUpload('2')}
                        className={classes.blueButton}
                    >
                        スイング2へ保存
                    </DominantButton>
                    <DominantButton onClick={handleClose}>
                        キャンセル
                    </DominantButton>
                </Box>
            </Card>
        );
    },
    { forwardRef: true },
);
