import { MovieEntity, StaffFavResponse } from './Api';
import { isNotEmptyString } from './utilityFuncs';

export interface VideoLike {
    id: string;
    src: string | undefined;
    thumbnail?: string;
}

export class RecordedVideo implements VideoLike {
    constructor(video: Blob, toolImage: File | undefined) {
        this.video = video;
        this.src = URL.createObjectURL(video);
        this.toolImage = toolImage;
    }

    public video: Blob;
    public src: string;
    public toolImage: File | undefined;

    public get type(): 'RecordedVideo' {
        return 'RecordedVideo';
    }

    get id(): string {
        return this.src;
    }
}

export interface MovieDisplay extends MovieEntity, VideoLike { }
export class MovieDisplay {
    constructor(movieEntry: MovieEntity) {
        Object.assign(this, movieEntry);
        this.src = undefined;
    }
    public get type(): 'MovieDisplay' {
        return 'MovieDisplay';
    }

    public get fetchable(): boolean {
        const { filepath, movie_thumbnail_filepath } = this;
        return (
            isNotEmptyString(filepath) &&
            isNotEmptyString(movie_thumbnail_filepath)
        );
    }

    public fetchSrc(): void {
        this.src = this.signed_url;
        this.thumbnail = this.thumbnail_signed_url;
    }

    get favId(): string {
        return `${this.customer_id_or_staff_id}_${this.created_at}`;
    }
}

export type VisibleVideo = MovieDisplay | RecordedVideo;

export class MovieFav {
    private readonly staff_id: string;
    public readonly created_at: string;
    public readonly movie: MovieDisplay;

    constructor(staffFavResponse: StaffFavResponse) {
        this.staff_id = staffFavResponse.staff_id;
        this.created_at = staffFavResponse.created_at;
        this.movie = new MovieDisplay({
            id: String(staffFavResponse.id),
            customer_id_or_staff_id: String(staffFavResponse.owner_id),
            created_at: staffFavResponse.created_at,
            lesson_id: staffFavResponse.lesson_id,
            filename: staffFavResponse.filename,
            filepath: staffFavResponse.filepath,
            club: staffFavResponse.club,
            yards: staffFavResponse.yards,
            tool_image_filename: staffFavResponse.tool_image_filename,
            tool_image_filepath: staffFavResponse.tool_image_filepath,
            movie_thumbnail_filename: staffFavResponse.movie_thumbnail_filename,
            movie_thumbnail_filepath: staffFavResponse.movie_thumbnail_filepath,
            movie_type: staffFavResponse.movie_type,
            signed_url: staffFavResponse.signed_url,
            thumbnail_signed_url: staffFavResponse.thumbnail_signed_url,
        });
    }

    get id(): string {
        return this.movie.favId;
    }
}
