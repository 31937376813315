import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PencilIcon from '../../../assets/pencil.svg';
import WindowIcon from '../../../assets/windows.svg';
import { LessonPropertyTabContext } from '../../../contexts/LessonPropertyTabContext';
import { useStore } from '../../../contexts/StoreContext';
import { useQuestionnaireMapCreater } from '../../../hooks/useQuestionnaireMapCreater';
import { CustomerDisplay } from '../../../store/LessonStore';
import { TODAY_LESSON } from '../../const/LessonProperties';
import { Routes } from '../../const/Routes';
import { colors } from '../../const/Styles';
import LessonCommentModal from '../input/LessonCommentModal';
import LessonTargetModal from '../input/LessonTargetModal';
import ActionableLessonCard from '../surface/ActionableLessonCard';
import LessonCard from '../surface/LessonCard';
import RegisteredMovieThumbnail from '../surface/RegisteredMovieThumbnail';
import LessonTargets from './LessonTargets';
import PastLessons from './PastLessons';

type ContainterProps = {
    visible: boolean;
};
const GridContainer = styled.section<ContainterProps>`
    display: ${({ visible }) => (visible === true ? 'grid' : 'none')};
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: auto;
    gap: 4px;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: ${colors.lightGray};
    border-top: 4px solid ${colors.lightGray};
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const PreText = styled.p`
    margin: 0;
    white-space: pre-wrap;
    padding: 20px;
    box-sizing: border-box;
`;

type Props = {
    customer: CustomerDisplay;
    activeColor: string;
};

const LessonContent: React.FC<Props> = ({ customer, activeColor }) => {
    const { lessonStore, recordingStore } = useStore();
    const { selected } = useContext(LessonPropertyTabContext);
    const visible = useMemo(() => {
        return TODAY_LESSON === selected;
    }, [selected]);

    const [commentModalOpended, setCommentModalOpended] = useState(false);
    const handleCloseCommentModal = useCallback(() => {
        setCommentModalOpended(false);
    }, []);
    const handleOpenCommentModal = useCallback(() => {
        setCommentModalOpended(true);
    }, []);

    const [targetModalOpended, setTargetModalOpended] = useState(false);
    const handleCloseTargetModal = useCallback(() => {
        setTargetModalOpended(false);
    }, []);

    const handleOpenTargetModal = useCallback(() => {
        setTargetModalOpended(true);
    }, []);

    const location = useLocation();
    const history = useHistory();
    const handleClickSwingList = useCallback(() => {
        history.push(Routes.SWING_LIST, {
            customerId: customer.id,
            from: location.pathname,
        });
    }, [history, location, customer]);

    const questions = useQuestionnaireMapCreater(customer);
    const registeredVideo = recordingStore.getRegisteredVideo(
        String(customer.id),
    );
    return (
        <GridContainer visible={visible}>
            <ColumnContainer>
                <LessonCard title='スイング撮影'>
                    {registeredVideo?.map((video) => (
                        <RegisteredMovieThumbnail
                            key={video.id}
                            data={video}
                            customerId={String(customer.id)}
                        />
                    ))}
                </LessonCard>
                <ActionableLessonCard
                    title='過去のレッスン'
                    icon={WindowIcon ?? ''}
                    iconPrefix='スイング一覧'
                    onClick={handleClickSwingList}
                >
                    <PastLessons customer={customer} />
                </ActionableLessonCard>
            </ColumnContainer>
            <ColumnContainer>
                <ActionableLessonCard
                    title='本日のコメント'
                    icon={PencilIcon ?? ''}
                    onClick={handleOpenCommentModal}
                >
                    <PreText>
                        {lessonStore.getLessonComment(String(customer.id)) ||
                            '※【入力必須】コメントは必ず入力してください'}
                    </PreText>
                </ActionableLessonCard>
                <ActionableLessonCard
                    title='レッスン目標'
                    icon={PencilIcon ?? ''}
                    onClick={handleOpenTargetModal}
                >
                    <LessonTargets questions={questions} color={activeColor} />
                </ActionableLessonCard>
                <LessonCommentModal
                    customer={customer}
                    isOpended={commentModalOpended}
                    onClose={handleCloseCommentModal}
                />
                <LessonTargetModal
                    customer={customer}
                    isOpended={targetModalOpended}
                    questions={questions}
                    onClose={handleCloseTargetModal}
                    color={activeColor}
                />
            </ColumnContainer>
        </GridContainer>
    );
};

export default observer(LessonContent);
