import dateFormat from 'date-fns/format';
import {
    forwardRef,
    HTMLAttributes,
    useImperativeHandle,
    useMemo,
} from 'react';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import { FavoriteIcon, useFavorite } from '../../../hooks/useFavorite';
import useVideoJS from '../../../hooks/useVideoJs';
import { MovieDisplay } from '../../../utils/Video';
import { colors } from '../../const/Styles';
import TimelineImage from './TimelineImage';
import ToolImageViewer from './ToolImageViewer';

const Container = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    .vjs-control-bar {
        z-index: 3;
    }
    .vjs-big-play-button {
        z-index: 3;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;
    font-weight: bold;
`;

const PlayerName = styled.label`
    display: block;
    font-size: 21px;
    color: ${colors.black};
    font-weight: bold;
`;

const LessonInfomation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-conten: flex-end;
`;

const LessonInfomationColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 12px;
`;

const CreatedAt = styled.div`
    text-align: right;
`;

const InlineList = styled.ul`
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    > li {
        display: block;
        box-sizing: border-box;
        padding-right: 15px;
        &:last-child {
            padding-right: 0;
        }
        &:before {
            content: '●';
        }
    }
`;

const MovieIndexTitle = styled.span`
    font-weight: bold;
    font-size: 18px;
    color: ${colors.footerIconSelected};
    text-align: right;
`;

const MovieIndex = styled.span`
    margin: 0;
    padding: 0;
    margin-left: 20px;
    color: ${colors.footerIconSelected};
    font-size: 59px;
    font-weight: bold;
    line-height: 1;
`;

type GuideLineProps = {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
};

const HorizontalGuideLine = styled.p<GuideLineProps>`
    width: 100%;
    height: 1px;
    background-color: ${colors.red};
    opacity: 0.7;
    position: absolute;
    ${({ top, bottom, left, right }) => {
        const position = {};
        if (typeof left === 'number') {
            Object.assign(position, { left: `${left}px` });
        }
        if (typeof right === 'number') {
            Object.assign(position, { right: `${right}px` });
        }
        if (typeof top === 'number') {
            Object.assign(position, { top: `${top}px` });
        }
        if (typeof bottom === 'number') {
            Object.assign(position, { bottom: `${bottom}px` });
        }
        return position;
    }}
`;

const VerticalGuideLine = styled(HorizontalGuideLine)`
    width: 1px;
    height: 100%;
`;

const FavIcon = styled(FavoriteIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
`;

const Timeline = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 480px;
    > img {
        height: auto;
        width: 48px;
    }
`;

interface Props extends HTMLAttributes<Element> {
    movieDisplay: MovieDisplay;
    showFav?: boolean;
    showTimeLine?: boolean;
    showHeader?: boolean;
    movieIndex?: number;
    onChangeDuration?: (time: number) => void;
    reloadToolImage: boolean;
    showGuide?: boolean;
}

export type ForwardProps = {
    advance: () => void;
    goBack: () => void;
    togglePlay: () => Promise<void>;
};

const LessonMoviePlayer = forwardRef<ForwardProps, Props>(
    (
        {
            movieDisplay,
            showFav = true,
            showTimeLine = false,
            showHeader = true,
            movieIndex = undefined,
            reloadToolImage,
            showGuide = false,
            className,
        },
        ref,
    ) => {
        const { tempoStore, lessonStore } = useStore();
        const [faved, toggleFav] = useFavorite(movieDisplay);
        const { Video, thumbnails, actions } = useVideoJS({
            controls: true,
            responsive: true,
            sources: [{ src: movieDisplay.src ?? '' }],
            muted: true,
        });
        const { created_at, club, yards, customer_id_or_staff_id } =
            movieDisplay;

        const userName = useMemo(() => {
            return (
                tempoStore.resolveUserName(Number(customer_id_or_staff_id)) ||
                lessonStore.resolveCustomerName(Number(customer_id_or_staff_id)) ||
                []
            );
        }, [tempoStore, lessonStore, customer_id_or_staff_id]);

        const handleClickTimeline = (time: number) => {
            actions.skip(time);
        };

        useImperativeHandle(ref, () => ({
            advance: () => {
                actions.skipPlus();
            },
            goBack: () => {
                actions.skipMinus();
            },
            togglePlay: async () => {
                await actions.togglePlay();
            },
        }));

        return (
            <Container className={className}>
                {showHeader && (
                    <HeaderContainer>
                        <PlayerName className='userName'>
                            {userName.join(' ')}
                        </PlayerName>
                        <LessonInfomation>
                            <LessonInfomationColumn>
                                <CreatedAt>
                                    {dateFormat(
                                        new Date(created_at),
                                        'yyyy/MM/dd',
                                    )}
                                </CreatedAt>
                                <InlineList>
                                    <li>{club}</li>
                                    <li>{yards}y</li>
                                </InlineList>
                                {movieIndex && (
                                    <MovieIndexTitle>スイング</MovieIndexTitle>
                                )}
                            </LessonInfomationColumn>
                            {movieIndex && (
                                <MovieIndex>{movieIndex}</MovieIndex>
                            )}
                        </LessonInfomation>
                    </HeaderContainer>
                )}
                <VideoContainer>
                    {showFav && <FavIcon faved={faved} onClick={toggleFav} />}
                    <Video />
                    <ToolImageViewer
                        filePath={movieDisplay.tool_image_filename}
                        reload={reloadToolImage}
                    />
                    {showGuide && (
                        <>
                            <HorizontalGuideLine top={30} left={0} />
                            <HorizontalGuideLine bottom={45} left={0} />
                            <VerticalGuideLine left={96} />
                            <VerticalGuideLine right={96} />
                        </>
                    )}
                </VideoContainer>
                {showTimeLine && (
                    <Timeline className='timeline'>
                        {thumbnails?.map(([img, time]) => (
                            <TimelineImage
                                key={time}
                                src={img}
                                time={time}
                                onClick={handleClickTimeline}
                            ></TimelineImage>
                        ))}
                    </Timeline>
                )}
            </Container>
        );
    },
);

export default LessonMoviePlayer;
