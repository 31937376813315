import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
    CustomerEntity,
    EnqueteQuestionEntity,
    LessonCurriculumEntity,
    StaffEntity,
} from '../components/@types/mysqlEntities';
import {
    CustomerSaveForm,
    SaveCurriculumRequest,
    SaveEnqueteQuestion,
} from '../components/@types/request';
import { Club } from '../components/const/SwingClub';
import AuthStore from '../store/AuthStore';
import { emptyToNull } from './strings';
import { MovieTypeProperties } from '../components/const/MovieTypeProperties';
import { createLessonAchievementItemResponse } from '../factories/LessonAchievementItemFactory';
import { LoginKindTypes } from "../components/const/LoginKinds";

export interface CustomerDetail {
    best_score: string;
    avg_score: string;
    rounds_per_year: string;
    total_practice: string;
    club_good_at: string;
    club_favorite: string;
    years_golf: string;
    years_sport: string;
    home_course: string;
    memo: string;
    head_speed: string;
    distance: string;
    projectile: string;
}

export interface CustomerEnqueteAnswers {
    question_answer_1?: string;
    question_answer_2?: string;
    question_answer_3?: string;
    question_answer_4?: string;
    question_answer_5?: string;
    question_answer_6?: string;
    question_answer_7?: string;
    question_answer_8?: string;
    question_answer_9?: string;
    question_answer_10?: string;
}

export type ErrorResponse = {
    error: string;
    status: number;
    message: Record<string, string[]>;
};

export type ErrorMessages = Record<string, string[]>;

export interface CustomerRegisterErrorResponse {
    error: string;
    message: [
        profile_picture_filename?: string,
        email_error?: string,
        user_name_error?: string,
        name_sei_error?: string,
        name_mei_kana_error?: string,
        date_of_birth_error?: string,
        sex_error?: string,
        height_error?: string,
        grip_right_error?: string,
        grip_left_error?: string,
        dominant_hand_error?: string,
        purpose_begin_error?: string,
        target_error?: string,
        best_score_error?: string,
        avg_score_error?: string,
        rounds_per_year_error?: string,
        total_practice_error?: string,
        club_good_at_error?: string,
        club_favorite_error?: string,
        years_golf_error?: string,
        home_course_error?: string,
        memo_error?: string,
        head_speed_error?: string,
        distance_error?: string,
        projectile_error?: string,
    ];
}

export interface StaffRegisterErrorResponse {
    error: string;
    message: [
        type?: string,
        name_sei?: string,
        name_mei?: string,
        name_sei_kana?: string,
        name_mei_kana?: string,
        date_of_birth?: string,
        is_active?: string,
    ];
}

export interface StaffFavResponse {
    id: number;
    staff_id: string;
    created_at: string;
    owner_id: number;
    customer_id_or_staff_id: string;
    movie_created_at: string;
    lesson_id: string;
    filename: string;
    filepath: string;
    club: Club;
    yards: string;
    tool_image_filename: string;
    tool_image_filepath: string;
    movie_thumbnail_filename: string;
    movie_thumbnail_filepath: string;
    movie_type: MovieTypeProperties;
    signed_url: string;
    thumbnail_signed_url: string;
}

export interface MovieEntity {
    id: string;
    customer_id_or_staff_id: string;
    created_at: string;
    lesson_id: string;
    filename: string;
    filepath: string;
    club: Club;
    yards: string;
    tool_image_filename: string;
    tool_image_filepath: string;
    movie_thumbnail_filename: string;
    movie_thumbnail_filepath: string;
    movie_type: MovieTypeProperties;
    signed_url: string;
    thumbnail_signed_url: string;
}

export interface LoginResponse {
    id: string;
    company_id: string;
    name: string;
    login_kind: LoginKindTypes;
    updated_at: string;
    question_name_1: string;
    question_name_2: string;
    question_name_3: string;
    question_name_4: string;
    question_name_5: string;
    question_name_6: string;
    question_name_7: string;
    question_name_8: string;
    question_name_9: string;
    question_name_10: string;
}

const endpoint = process.env.REACT_APP_API_HOST as string;

const kicker = axios.create({
    baseURL: endpoint,
    withCredentials: true,
});

export interface LessonMasterEntity {
    id: number;
    tempo_id: string;
    created_at: string;
    name: string;
    curriculum_name_1: string;
    curriculum_name_2: string;
    curriculum_name_3: string;
    curriculum_name_4: string;
    curriculum_name_5: string;
    curriculum_name_6: string;
    curriculum_name_7: string;
    curriculum_name_8: string;
    curriculum_name_9: string;
    curriculum_name_10: string;
}

export interface LessonEntity {
    id: number;
    customer_id: string;
    created_at: string;
    lesson_master_id: string;
    staff_id: string;
    comment: string;
    curriculum_name_1: string;
    curriculum_value_1: string;
    curriculum_name_2: string;
    curriculum_value_2: string;
    curriculum_name_3: string;
    curriculum_value_3: string;
    curriculum_name_4: string;
    curriculum_value_4: string;
    curriculum_name_5: string;
    curriculum_value_5: string;
    curriculum_name_6: string;
    curriculum_value_6: string;
    curriculum_name_7: string;
    curriculum_value_7: string;
    curriculum_name_8: string;
    curriculum_value_8: string;
    curriculum_name_9: string;
    curriculum_value_9: string;
    curriculum_name_10: string;
    curriculum_value_10: string;
}

export interface LessonAchievementEntity {
    id: number;
    tempo_id: string;
    customer_id: string;
    staff_id: string;
    lesson_master_id: string;
    name: string;
    comment: string;
    curriculum_achievements: CurriculumAchievementEntity[];
    created_at: string;
}

export interface CurriculumAchievementEntity {
    id: number;
    lesson_curriculum_id: string;
    curriculum_name_at_registered: string;
    curriculum_name_current: string;
    display_order: string;
    rate: string;
    is_deleted: boolean;
    created_at: string;
}

export interface EnqueteItemEntity {
    id: string;
    question_name_1: string;
    question_name_2: string;
    question_name_3: string;
    question_name_4: string;
    question_name_5: string;
    question_name_6: string;
    question_name_7: string;
    question_name_8: string;
    question_name_9: string;
    question_name_10: string;
}

export interface CustomerDetailedInformationEntity {
    best_score: string;
    avg_score: string;
    rounds_per_year: string;
    total_practice: string;
    club_good_at: string;
    club_favorite: string;
    years_golf: string;
    years_sport: string;
    home_course: string;
    memo: string;
    head_speed: string;
    distance: string;
    projectile: string;
}

export type StaffFormType = Omit<
    StaffEntity,
    'created_at' | 'updated_at' | 'tempo_id'
> & {
    is_deleted: boolean
};
export default class Api {
    protected static async kick<T>(config: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await kicker.request(config);
        return response.data;
    }

    public static async getCustomers(
        tempoId: string,
        showDeleted = false
    ): Promise<CustomerEntity[]> {
        return this.kick({
            url: showDeleted ? `tempo/${tempoId}/customer/deleted` : `tempo/${tempoId}/customer`,
        });
    }

    public static async getCustomerDetail(
        customerId: number,
    ): Promise<CustomerDetail[]> {
        //:id/detailed
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${customerId}/detailed`,
        });
    }

    public static async fetachCusomerLatestEnqueteAnswer(
        customerId: number,
    ): Promise<CustomerEnqueteAnswers> {
        //:id/enquete-answers
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${customerId}/enquete-answers`,
        });
    }

    public static async registerCustomer(
        params: Omit<CustomerSaveForm, 'id'>,
    ): Promise<{
        tempo_id: string;
        id: string;
        filename: string;
        created_at: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/`,
            method: 'POST',
            data: {
                tempo_id: AuthStore.tempoId,
                ...params.basicInfo,
                ...params.detailInfo,
                ...params.questionnaire,
                profile_picture_filename: params.image,
            },
        });
    }

    public static async editCustomer(params: CustomerSaveForm): Promise<{
        tempo_id: string;
        id: string;
        filename: string;
        created_at: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${params.id}/update`,
            method: 'POST',
            data: {
                tempo_id: AuthStore.tempoId,
                ...params.detailInfo,
                ...params.basicInfo,
                ...params.questionnaire,
                profile_picture_filename: params.image,
            },
        });
    }

    public static async deleteCustomer(id: number): Promise<{
        tempo_id: string;
        id: string;
        filename: string;
        created_at: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${id}`,
            method: 'DELETE'
        })
    }

    public static async createLesson(
        customerId: string,
        lessonMasterId: number,
        staffId: string,
        comment: string,
        records: string[],
    ): Promise<LessonEntity> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${customerId}/lesson`,
            method: 'POST',
            data: {
                customer_id: customerId,
                lesson_master_id: lessonMasterId,
                staff_id: staffId,
                comment: comment,
                curriculum_value_1: records[0] || '',
                curriculum_value_2: records[1] || '',
                curriculum_value_3: records[2] || '',
                curriculum_value_4: records[3] || '',
                curriculum_value_5: records[4] || '',
                curriculum_value_6: records[5] || '',
                curriculum_value_7: records[6] || '',
                curriculum_value_8: records[7] || '',
                curriculum_value_9: records[8] || '',
                curriculum_value_10: records[9] || '',
            },
        });
    }

    /**
     * レッスン実績削除
     * @param customerId
     * @param lessonArchivementId
     * @returns
     */
    public static async deleteAchievedLesson(
        customerId: string,
        lessonArchivementId: string,
    ): Promise<LessonEntity> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${customerId}/lesson_achievement/${lessonArchivementId}`,
            method: 'DELETE'
        })
    }

    public static async fetchCustomerPastLessons(
        customerId: number,
    ): Promise<LessonEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${customerId}/lesson`,
            method: 'GET',
        });
    }

    public static async fetchCustomerRecentLessonAchievement(
        customerId: number,
        lessonMasterId: number,
    ): Promise<LessonAchievementEntity | null> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${customerId}/lesson/recent_achievement/${lessonMasterId}`,
            method: 'GET',
        });
    }

    public static async fetchLatestLessonAchievement(
        customerId: number,
        lessonMasterId: number,
    ): Promise<LessonAchievementEntity | null> {
        const fetchedLessonAchievement =
            await Api.fetchCustomerRecentLessonAchievement(
                customerId,
                lessonMasterId,
            );
        return fetchedLessonAchievement
            ? createLessonAchievementItemResponse(fetchedLessonAchievement)
            : null;
    }

    public static async editLessonComment(
        customerId: string,
        lessonAchievementId: string,
        comment: string,
    ): Promise<LessonEntity> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/lesson_achievement/comment`,
            method: 'PATCH',
            data: {
                id: Number(lessonAchievementId),
                tempo_id: Number(AuthStore.tempoId),
                customer_id: Number(customerId),
                comment: comment,
            },
        });
    }

    public static async getS3SignedUrlForUploadStaffPicture(
        fileName: string,
    ): Promise<{
        presignedUrl: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/profile-picture/staff/presigned-url-for-upload`,
            method: 'POST',
            data: { filename: fileName },
        });
    }

    public static async getS3SignedUrlForDownloadStaffPicture(
        filePath: string,
    ): Promise<{
        presignedUrl: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/profile-picture/staff/presigned-url-for-download`,
            method: 'POST',
            data: { filepath: filePath },
        });
    }

    public static async getS3SignedUrlForUploadCustomerPicture(
        fileName: string,
    ): Promise<{
        presignedUrl: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/profile-picture/customer/presigned-url-for-upload`,
            method: 'POST',
            data: { filename: fileName },
        });
    }

    public static async getS3SignedUrlForUploadMovie(
        fileName: string,
    ): Promise<{
        presignedUrl: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/movie/presigned-url-for-upload`,
            method: 'POST',
            data: { filename: fileName },
        });
    }

    public static async getS3SignedUrlForDownloadMovie(
        filePath: string,
    ): Promise<{
        presignedUrl: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/movie/presigned-url-for-download`,
            method: 'POST',
            data: { filepath: filePath },
        });
    }

    public static async uploadToS3(
        presignedUrl: string,
        file: File,
    ): Promise<AxiosResponse<void>> {
        return axios({
            method: 'PUT',
            url: presignedUrl,
            headers: {
                'Content-Type': file.type,
            },
            data: file,
        });
    }

    public static async registerStaff(
        type: string,
        nameSei: string,
        nameMei: string,
        nameSeiKana: string,
        nameMeiKana: string,
        dateOfBirth: string,
        sex: string,
        certification1Name: string,
        certification1Date: string,
        certification2Name: string,
        certification2Date: string,
        certification3Name: string,
        certification3Date: string,
        image: string,
    ): Promise<{
        created_at: string;
        filename: string;
        id: number;
        tempo_id: number;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/staff`,
            method: 'POST',
            data: {
                tempo_id: AuthStore.tempoId,
                type: type,
                name_sei: nameSei,
                name_mei: nameMei,
                name_sei_kana: nameSeiKana,
                name_mei_kana: nameMeiKana,
                date_of_birth: dateOfBirth,
                sex: sex,
                certification1_name: certification1Name,
                certification1_date: emptyToNull(certification1Date),
                certification2_name: certification2Name,
                certification2_date: emptyToNull(certification2Date),
                certification3_name: certification3Name,
                certification3_date: emptyToNull(certification3Date),
                profile_picture_filename: image,
            },
        });
    }

    public static async updateStaff(data: StaffFormType): Promise<StaffEntity> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/staff/${data.id}/update`,
            method: 'POST',
            data: {
                ...data,
                tempo_id: AuthStore.tempoId,
            },
        });
    }

    public static async deleteStaff(id: number): Promise<StaffEntity> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/staff/${id}`,
            method: 'DELETE'
        })
    }

    public static async fetchStaffs(tempoId: string, showDeleted = false): Promise<StaffEntity[]> {
        return this.kick({
            url: showDeleted ? `tempo/${AuthStore.tempoId}/staff/deleted` : `tempo/${AuthStore.tempoId}/staff`,
            params: { tempo_id: tempoId },
            method: 'GET',
        });
    }

    public static async fetchStaffMovies(
        staffId: number,
    ): Promise<MovieEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/staff/${staffId}/movie`,
            params: { staff_id: staffId },
            method: 'GET',
        });
    }

    public static async fetchAllStaffMovies(): Promise<MovieEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/staff/movies`,
            method: 'GET',
        });
    }

    public static async fetchCustomerMovies(
        customerId: number,
    ): Promise<MovieEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${customerId}/movie`,
            params: { customer_id: customerId },
            method: 'GET',
        });
    }

    public static async createCoachVideo(
        staffId: string,
        club: string,
        yard: string,
        fileExtension: string,
    ): Promise<{
        lesson_id: string;
        filename: string;
        club: string;
        yards: string;
        customer_id_or_staff_id: string;
        created_at: string;
        movie_thumbnail_filename: string;
        movie_thumbnail_filepath: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/staff/${staffId}/movie`,
            method: 'POST',
            data: {
                customer_id_or_staff_id: staffId,
                club: club,
                yards: yard,
                file_extension: fileExtension,
            },
        });
    }

    public static async createCustomerVideo(
        customerId: string,
        lessonMasterId: number,
        lessonId: string,
        club: string,
        yard: string,
        fileExtension: string,
    ): Promise<{
        id: number;
        lesson_id: string;
        filename: string;
        club: string;
        yards: string;
        customer_id_or_staff_id: string;
        created_at: string;
        movie_thumbnail_filename: string;
        movie_thumbnail_filepath: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${customerId}/movie`,
            method: 'POST',
            data: {
                customer_id_or_staff_id: customerId,
                lesson_master_id: lessonMasterId,
                lesson_id: lessonId,
                club: club,
                yards: yard,
                file_extension: fileExtension,
            },
        });
    }

    public static async fetchLessons(
        tempoId: string,
    ): Promise<LessonMasterEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/lesson/course`,
            params: { tempo_id: tempoId },
            method: 'GET',
        });
    }

    public static async createLessonCourse(
        tempoId: string,
        name: string,
        curriculumName1?: string,
        curriculumName2?: string,
        curriculumName3?: string,
        curriculumName4?: string,
        curriculumName5?: string,
        curriculumName6?: string,
        curriculumName7?: string,
        curriculumName8?: string,
        curriculumName9?: string,
        curriculumName10?: string,
    ): Promise<LessonMasterEntity> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/lesson/course`,
            method: 'POST',
            data: {
                tempo_id: tempoId,
                name: name,
                curriculum_name_1: curriculumName1 || '',
                curriculum_name_2: curriculumName2 || '',
                curriculum_name_3: curriculumName3 || '',
                curriculum_name_4: curriculumName4 || '',
                curriculum_name_5: curriculumName5 || '',
                curriculum_name_6: curriculumName6 || '',
                curriculum_name_7: curriculumName7 || '',
                curriculum_name_8: curriculumName8 || '',
                curriculum_name_9: curriculumName9 || '',
                curriculum_name_10: curriculumName10 || '',
            },
        });
    }

    public static async updateLessonCourse(
        tempoId: string,
        name: string,
        lastCreatedAt: string,
        curriculumName1?: string,
        curriculumName2?: string,
        curriculumName3?: string,
        curriculumName4?: string,
        curriculumName5?: string,
        curriculumName6?: string,
        curriculumName7?: string,
        curriculumName8?: string,
        curriculumName9?: string,
        curriculumName10?: string,
    ): Promise<LessonMasterEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/lesson/course/update`,
            method: 'POST',
            data: {
                tempo_id: tempoId,
                name: name,
                last_created_at: lastCreatedAt,
                curriculum_name_1: curriculumName1 || '',
                curriculum_name_2: curriculumName2 || '',
                curriculum_name_3: curriculumName3 || '',
                curriculum_name_4: curriculumName4 || '',
                curriculum_name_5: curriculumName5 || '',
                curriculum_name_6: curriculumName6 || '',
                curriculum_name_7: curriculumName7 || '',
                curriculum_name_8: curriculumName8 || '',
                curriculum_name_9: curriculumName9 || '',
                curriculum_name_10: curriculumName10 || '',
            },
        });
    }

    public static async deleteLessonCourse(lessonMasterId: number): Promise<LessonMasterEntity> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/lesson/course/${lessonMasterId}`,
            method: 'DELETE',
        });
    }

    /**
     * カリキュラム取得
     * @param lessonMasterId
     * @returns
     */
    public static async fetchCurriculums(
        lessonMasterId: number,
    ): Promise<LessonCurriculumEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/lesson/course/curriculum/${lessonMasterId}`,
            method: 'GET',
        });
    }

    /**
     * カリキュラム単独保存
     * @param data
     * @returns
     */
    public static async saveCurriculum(
        data: SaveCurriculumRequest,
    ): Promise<LessonCurriculumEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/lesson/course/curriculum`,
            method: 'POST',
            data: { ...data },
        });
    }

    /**
     * カリキュラム削除
     * @param id
     * @param lessonMasterId
     * @returns
     */
    public static async deleteCurriculum(
        id: number,
        lessonMasterId: number,
    ): Promise<LessonCurriculumEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/lesson/course/curriculum`,
            method: 'DELETE',
            data: { id, lesson_master_id: lessonMasterId },
        });
    }

    public static async fetchEnqueteItems(
        tempoId: string,
    ): Promise<EnqueteQuestionEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/enquete-item`,
            params: { tempo_id: tempoId },
            method: 'GET',
        });
    }

    public static async deleteEnqueteItem(
        id: number,
        tempoId: number,
    ): Promise<EnqueteQuestionEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/enquete-item`,
            method: 'DELETE',
            data: {
                id,
                tempo_id: tempoId,
            },
        });
    }

    public static async saveEnqueteItem(
        params: SaveEnqueteQuestion,
    ): Promise<EnqueteQuestionEntity[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/enquete-item`,
            method: 'POST',
            data: params,
        });
    }

    public static async fetchFavMovies(
        customerId: number,
    ): Promise<StaffFavResponse[]> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/${customerId}/fav-movies`,
            method: 'GET',
        });
    }

    public static async addFavToCustomerMovie(
        movieId: string,
        selfStaffId: number,
        customer_id: string,
        movie_created_at: string,
    ): Promise<unknown> {
        // FIXME: 毎回最新の一覧を引かなければならないので、追加したデータを返せるようにする
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/fav-movie`,
            method: 'POST',
            data: {
                movie_id: Number(movieId),
                staff_id: selfStaffId,
                customer_id_or_staff_id: customer_id,
                movie_created_at,
            },
        });
    }

    public static async removeFavFromCustomerMovie(
        movieId: string,
        selfStaffId: number,
        customer_id: string,
        movie_created_at: string,
    ): Promise<unknown> {
        // FIXME: 毎回最新の一覧を引かなければならないので、削除したデータを返せるようにする
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/customer/fav-movie`,
            method: 'DELETE',
            data: {
                movie_id: Number(movieId),
                staff_id: selfStaffId,
                customer_id_or_staff_id: customer_id,
                movie_created_at,
            },
        });
    }

    public static async updateToolImage(
        id: number,
        userId: string,
        createdAt: string,
        filename: string,
    ): Promise<{
        updated_at: string;
        created_at: string;
        customer_id_or_staff_id: string;
        tool_image_filename: string;
        tool_image_filepath: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/movie/tool-image`,
            method: 'POST',
            data: {
                id,
                customer_id_or_staff_id: userId,
                movie_created_at: createdAt,
                movie_filename: filename,
            },
        });
    }

    public static async getS3SignedUrlForUploadToolImage(
        fileName: string,
    ): Promise<{
        presignedUrl: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/tool-image/presigned-url-for-upload`,
            method: 'POST',
            data: { filename: fileName },
        });
    }

    public static async getS3SignedUrlForDownloadToolImage(
        filePath: string,
    ): Promise<{
        presignedUrl: string;
    }> {
        return this.kick({
            url: `tempo/${AuthStore.tempoId}/tool-image/presigned-url-for-download`,
            method: 'POST',
            data: { filepath: filePath },
        });
    }

    public static async fetchLogin(
        id: string,
        password: string,
    ): Promise<LoginResponse> {
        return this.kick({
            url: 'tempo/login',
            params: { id, password },
            method: 'POST',
        });
    }

    public static async checkLogin(): Promise<LoginResponse> {
        return this.kick({
            url: 'tempo/login/check',
            method: 'GET',
        });
    }

    public static async logout(): Promise<void> {
        return this.kick({
            url: 'tempo/logout',
            params: {},
            method: 'POST',
        });
    }
}
